import { useCallback } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { localStorageGet } from '../utils/localStorageHelper';
import useYupSchemaBuilder from './useYupSchemaBuilder';

/**
 * Custom hook to handle initial configuration and UI events of address page UI form.
 * Handles how to format properly location address by using google maps API, builts form validation
 * configuration by using methods from yup schema builder hook and handles form submission UI events.
 *
 * @param { Object } uiEvents - Set of UI events handlers for UI form submission
 * @return { Object } - { formik, handleSubmit, handleOnKeyPress } - `formik` property returns formik
 * configuration to be used in the UI logic of the UI page component. `handleSubmit` and `handleOnKeyPress`
 * are functions to handle UI form submission but with slight difference, the latter will submit the form
 * by hitting `enter` key, the former with a single `click`.
 * */
const useAddressUIForm = ({ handleSubmitFn, handleOnKeyPressFn }) => {
  const {
    getYupShape,
    zipCodeValidator,
    textValidator,
  } = useYupSchemaBuilder();
  const history = useHistory();
  const { location } = history;
  const addressFormSchema = getYupShape({
    streetAddress: textValidator({ required: true }),
    zip: zipCodeValidator(),
    city: textValidator({ required: true, noWhitespace: true }),
    state: textValidator({ required: true, noWhitespace: true }),
  });
  const addressFromLS = localStorageGet('address');
  const getFormatedAddress = useCallback(() => {
    const result = {
      streetNumber: '',
      streetName: '',
      streetAddress: '',
      apartment: '',
      city: '',
      state: '',
      zip: '',
    };

    // if coming from off boarding or next page get the address from the local storage
    if (history.action === 'POP' && addressFromLS) {
      return addressFromLS.address;
    }

    // If props from the google search were passed to this route
    // then enter block of code
    if (history.action === 'PUSH' && location && location.state) {
      const suggestedAddressesArray =
        location.state.googleMapsSuggestedAddress.addressComponent;

      const mappableKeys = {
        streetNumber: ['street_number'],
        streetName: ['route'],
        zip: ['postal_code'],
        city: ['locality', 'neighborhood', 'sublocality_level_1'],
        state: ['administrative_area_level_1'],
      };

      suggestedAddressesArray.forEach((item) => {
        if (item.types.length > 1) {
          item.types.forEach((type) => {
            Object.keys(mappableKeys).forEach((key) => {
              const mappableKey = mappableKeys[key];
              if (mappableKey.includes(type)) {
                result[key] = item.long_name;
              }
            });
          });
        } else if (item.types[0]) {
          Object.keys(mappableKeys).forEach((key) => {
            const mappableKey = mappableKeys[key];
            if (mappableKey.includes(item.types[0])) {
              result[key] = item.long_name;
            }
          });
        }
      });

      result.streetAddress = `${result.streetNumber} ${result.streetName}`;

      return result;
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history]);

  const { streetAddress, apartment, city, state, zip } = getFormatedAddress();

  const handleSubmit = (values) => {
    handleSubmitFn(values);
  };

  const formik = useFormik({
    initialStatus: {
      existingValues: {
        streetAddress,
        apartment,
        city,
        zip,
        state,
      },
    },
    initialValues: {
      streetAddress,
      apartment,
      city,
      state,
      zip,
    },
    validateOnMount: !!streetAddress || !!city || !!state || !!zip,
    validationSchema: addressFormSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleOnKeyPress = (evt) => {
    handleOnKeyPressFn(evt, formik);
  };

  return { formik, handleSubmit, handleOnKeyPress };
};

export default useAddressUIForm;
