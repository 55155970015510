import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter as Router } from 'react-router-dom';
import * as vwoSDK from 'vwo-node-sdk';

import '@fontsource/commissioner/100.css';
import '@fontsource/commissioner/200.css';
import '@fontsource/commissioner/300.css';
import '@fontsource/commissioner/400.css';
import '@fontsource/commissioner/500.css';
import '@fontsource/commissioner/600.css';
import '@fontsource/commissioner/700.css';
import '@fontsource/commissioner/800.css';

import styled from 'styled-components';

import Loader from './components/Loader';

import Routes from './routes';
import store from './store';
import {
  AppointmentContext,
  AvailableAppointmentsContext,
  VwoContext,
  CardscanContext,
} from './utils/context';
import useWindowOrtientationChange from './hooks/useWindowOrientationChange';
import UserProvider from './providers/UserProvider';

export const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY
);

function App() {
  const [appointment, setAppointment] = useState({
    Appointment: false,
    AppointmentTime: null,
    AppointmentTimeNode: -1,
    AppointmentDate: null,
    AppointmentFullDate: null,
    SubscriptionStatus: '',
  });
  const [availableAppointments, setAvailableAppointments] = useState({
    dates: [],
    times: {},
  });
  const [vwoClientInstance, setVwoClientInstance] = useState({});
  const [insuranceInformation, setInsuranceInformation] = useState({});

  useEffect(() => {
    async function initVWO() {
      const settingsFile = await vwoSDK.getSettingsFile(
        process.env.REACT_APP_VWO_ACCT_ID,
        process.env.REACT_APP_VWO_SDK_KEY
      );
      const vwoClient = vwoSDK.launch({
        settingsFile,
      });

      setVwoClientInstance(vwoClient);
    }
    initVWO();
  }, []);

  useWindowOrtientationChange();

  return (
    <Provider store={store}>
      <Loader />
      <VwoContext.Provider value={[vwoClientInstance, setVwoClientInstance]}>
        <CardscanContext.Provider
          value={[insuranceInformation, setInsuranceInformation]}
        >
          <AppointmentContext.Provider value={[appointment, setAppointment]}>
            <AvailableAppointmentsContext.Provider
              value={[availableAppointments, setAvailableAppointments]}
            >
              <UserProvider>
                <BodyContainer>
                  <Router>
                    <Routes />
                  </Router>
                </BodyContainer>
              </UserProvider>
            </AvailableAppointmentsContext.Provider>
          </AppointmentContext.Provider>
        </CardscanContext.Provider>
      </VwoContext.Provider>
    </Provider>
  );
}

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: row;
  position: relative;
  justify-content: stretch;
`;

export default App;
