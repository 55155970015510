import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import useWaitlistPageUILogic from '../../hooks/useWaitlistPageUILogic';
import { setIsFetching } from '../../actions';
import { pageViewEvent } from '../../actions/segment';
import withTracker from '../../wrappers/withTracker';
import AnimatedRoute from '../../components/AnimatedRoute';
import OshiInput from '../../components/OshiInput';
import OshiNextButton from '../../components/OshiNextButton';
import Typography from '../../atoms/Typography';
import { ROUTES } from '../../constants/routes';

import { palette } from '../../theme/palette';

/**
 * @function Waitlist
 * @description Based on NewParter page, shows Waitlist page for the new generic sign up flow.
 * Its particular hybrid functionality makes this UI component to display different UI components.
 * Its main flow when user's current health plan is not yet available for Oshi and this page
 * displays a form-based page to save user's current health plan and employer they are enrolled to.
 * @param {(state: boolean): void} setIsFetching - Redux store action based function to show/hide loading mask screen.
 * @param {(name: string): void} pageViewEvent - Analytics segment action function to trigger when UI page is loaded.
 * @returns {React.ReactElement}
 */
const Waitlist = ({ setIsFetching, pageViewEvent }) => {
  const {
    animationDirection,
    setAnimationDirection,
    formik,
    handleJoinWaitlistClick,
    isValid,
    showErrors,
  } = useWaitlistPageUILogic({ setIsFetching, pageViewEvent });


  return (
    <AnimatedRoute
      nextRoute={ROUTES.OFF_BOARDING}
      title={`We'll let you know when Oshi Health is available to you.`}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      showProgressBar={false}
      animatedContentStyles={{
        padding: '16px 24px 24px 24px',
      }}
    >
      <SubcopyContainer>
        <Typography
          type={'paragraph'}
          styles={{ color: palette.coolGray500, fontWeight: '400' }}
        >
          It looks like Oshi Health isn't yet offered through your employer or
          health plan. Enter your information below and we'll notify you when
          our digestive care becomes available to you.
        </Typography>
      </SubcopyContainer>
      <Form>
        <TwoColumnContainer>
          <InputContainer>
            <OshiInput
              autoFocus
              id='firstName'
              type='text'
              label='First Name'
              onChange={formik.handleChange}
              formik={formik}
              segmentLabel='Patient Info - First Name - Waitlist'
              value={formik.values.firstName}
              labelStyles={{
                fontSize: '15px',
                fontWeight: '400',
                lineHeight: '22.5px',
              }}
              error={showErrors && formik.errors.firstName}
            />
            {showErrors && formik.errors.firstName && (
              <ErrorText>{formik.errors.firstName}</ErrorText>
            )}
          </InputContainer>

          <InputContainer>
            <OshiInput
              id='lastName'
              type='text'
              label='Last Name'
              onChange={formik.handleChange}
              segmentLabel='Patient Info - Last Name - Waitlist'
              formik={formik}
              value={formik.values.lastName}
              labelStyles={{
                fontSize: '15px',
                fontWeight: '400',
                lineHeight: '22.5px',
              }}
              error={showErrors && formik.errors.lastName}
            />
            {showErrors && formik.errors.lastName && (
              <ErrorText>{formik.errors.lastName}</ErrorText>
            )}
          </InputContainer>
        </TwoColumnContainer>
        <InputContainer>
          <OshiInput
            id='email'
            type='text'
            label='Email'
            segmentLabel='Patient Info - Email Input - Waitlist'
            isValid={isValid}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={showErrors && formik.errors.email}
          />
          {showErrors && formik.errors.email && showErrors && (
            <ErrorText>{formik.errors.email}</ErrorText>
          )}
        </InputContainer>
        <TwoColumnContainer>
          <OshiInput
            id='healthPlan'
            type='text'
            label='Health Plan'
            onChange={formik.handleChange}
            formik={formik}
            segmentLabel='Patient Info - Health Plan - Waitlist'
            value={formik.values.healthPlan}
            secondaryLabel={'Optional'}
            labelStyles={{
              fontSize: '15px',
              fontWeight: '400',
              lineHeight: '22.5px',
            }}
          />

          <OshiInput
            id='employer'
            type='text'
            label='Employer'
            onChange={formik.handleChange}
            segmentLabel='Patient Info - Employer - Waitlist'
            formik={formik}
            value={formik.values.employer}
            secondaryLabel={'Optional'}
            labelStyles={{
              fontSize: '15px',
              fontWeight: '400',
              lineHeight: '22.5px',
            }}
          />
        </TwoColumnContainer>
      </Form>
      <OshiNextButton
        disabled={!formik.dirty}
        onClick={handleJoinWaitlistClick}
        buttonTitle='Join Waitlist'
        containerStyles={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'flex-end',
          margin: '24px 0',
        }}
      />
    </AnimatedRoute>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  gap: 24px;
  margin-top: 0px;
  @media only screen and (max-width: 769px) {
    margin-top: 42px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const SubcopyContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0;
  letter-spacing: 0.04em;
  @media only screen and (min-width: 769px) {
    margin: 0 0px 24px 0px;
  }
`;

const ErrorText = styled.div`
  color: ${palette.error};
  font-family: 'Usual', serif;
  font-size: 12px;

  @media (max-width: 500px) {
    text-align: center;
  }
`;

const TwoColumnContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  @media only screen and (max-width: 769px) {
    flex-direction: column;
  }
`;
export default connect(null, { setIsFetching, pageViewEvent })(
  withTracker(Waitlist, 'Signup - Waitlist - Page View')
);
