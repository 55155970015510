import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import Lottie from 'lottie-react-web';
import { palette } from '../../theme/palette';
import TopRight from '../../assets/testimonial background - teal blob top right.json';
import BottomLeft from '../../assets/testimonial background - melon blob bottom left.json';
import OshiNextButton from '../../components/OshiNextButton';
import useWindowSize from '../../hooks/useWindowSize';
import withTracker from '../../wrappers/withTracker';
import userPicture from '../../img/scott-simplified 1.png';

function Testimonials() {
  const history = useHistory();
  const { screenSize } = useWindowSize();

  const bgLottieRef = useRef(null);

  const handleNext = () => {
    history.push('health-goals');
  };

  const getTopRight = () => (
    <Lottie
      options={{
        animationData: TopRight,
        loop: false,
        autoplay: true,
      }}
      ariaRole='none'
    />
  );

  const getBottomLeft = () => (
    <Lottie
      options={{
        animationData: BottomLeft,
        loop: false,
        autoplay: true,
      }}
      isPaused={true}
      ref={bgLottieRef}
      ariaRole='none'
    />
  );

  return (
    <PageContainer>
      <BackgroundContainer>
        <TopRightContainer>{getTopRight()}</TopRightContainer>
        <BottomLeftContainer>{getBottomLeft()}</BottomLeftContainer>
      </BackgroundContainer>
      <MainAnimationContainer>
        <TextContent>Your gut health is our priority</TextContent>
        <Headline>
          “Oshi Health has been a whole new level of care. I feel so much better
          equipped to handle my digestive issues and am {''}
          <Highlight>
            <span>back to living life uninterrupted by pain.”</span>
          </Highlight>
        </Headline>

        <UserContainer>
          <UserAvatar src={userPicture} alt='User profile picture'></UserAvatar>
          <UserName>Scott, Pennsylvania</UserName>
        </UserContainer>
        <NextBtnContainer>
          <NextBtnWrapper>
            <OshiNextButton
              containerStyles={{
                boxSizing: 'border-box',
                bottom: 0,
                padding: screenSize.isMobile ? '0 24px' : '0',
                left: 0,
                flex: 0,
                backgroundColor: 'transparent',
                margin: 'auto',
                maxWidth: '648px',
              }}
              buttonContainerStyles={{ alignItems: 'center' }}
              styles={{ width: '100%' }}
              onClick={handleNext}
              buttonTitle={
                <span>
                  Continue <RightIconArrow>→</RightIconArrow>
                </span>
              }
            />
          </NextBtnWrapper>
        </NextBtnContainer>
      </MainAnimationContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
`;

const BackgroundContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${palette.navy500};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MainAnimationContainer = styled.div`
  width: 100%;
  height: min-content;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px;
  z-index: 1;
  overflow: hidden;
`;

const BottomLeftContainer = styled.div`
  position: fixed;
  height: 100vh;
  transform: scale(4, 4) translate(-10%, 15%);
  @media only screen and (min-width: 769px) {
    transform: scale(3, 3) translate(-15%, 25%);
  }
`;

const TopRightContainer = styled.div`
  position: fixed;
  height: 100vh;
  transform: scale(3, 3) translate(40%, -30%);
  right: 0;
  top: 0;
  @media only screen and (min-width: 769px) {
    transform: scale(2.5, 2.5) translate(30%, -50%);
  }
`;

const RightIconArrow = styled.span`
  font-size: 1em;
  padding-left: 10px;

  @media only screen and (min-width: 769px) {
    padding-left: 8px;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%); 
    opacity: 0; 
  }
  50% {
    transform: translateX(5%);
    opacity: 1;
  }
  to {
    transform: translateX(0); 
    opacity: 1;
  }
`;

const slideInFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0; 
  }
  50% {
    transform: translateY(-5%);
    opacity: 1;
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
`;

const highlightAnimation = keyframes`

  0%{
  /* color: ${palette.white}; */
  }
  100% {
    background-position: 0 0;
  }
`;

const highlightText = keyframes`

  0%{
  color: ${palette.white};
  }
  100% {
    background-position: 0 0;
  }
`;

const Highlight = styled.span`
  background-color: none;
  background: linear-gradient(
    90deg,
    ${palette.melon300} 50%,
    rgba(255, 255, 255, 0) 50%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  font-weight: 300;
  animation: ${highlightAnimation} 1s ease-out forwards;
  animation-delay: 2.5s;

  span {
    background-color: none;
    background: linear-gradient(
      90deg,
      ${palette.navy} 50%,
      rgba(255, 255, 255, 0) 50%
    );
    font-weight: 400;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${highlightText} 1s ease-out forwards;
    animation-delay: 2.5s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
  animation: ${slideInFromBottom} 1s ease-out forwards;
  animation-delay: 2.5s;
  opacity: 0;
  min-width: max-content;
`;
const UserAvatar = styled.img`
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
`;

const UserName = styled.div`
  font-family: 'Commissioner';
  font-weight: 00;
`;

const Headline = styled.h1`
  color: ${palette.white};
  font-family: 'Commissioner';
  font-size: 32px;
  font-style: normal;
  font-weight: 200;
  line-height: 130%;
  flex: 0;
  opacity: 0;
  animation: ${slideInFromBottom} 1s ease-out forwards;
  animation-delay: 2s;
`;

const TextContent = styled.div`
  color: ${palette.white};
  font-family: 'Commissioner';
  font-size: 22px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  font-size: 18px;
  flex: 0;
  opacity: 0;
  animation: ${slideIn} 1s ease-out forwards;
  animation-delay: 1s;
  margin-bottom: 32px;
`;

const NextBtnWrapper = styled.div`
  min-height: 91px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  animation: ${slideInFromBottom} 0.5s ease-out forwards;
  animation-delay: 2.2s;

  @media only screen and (min-width: 769px) {
    position: unset;
    background-color: transparent;
  }
`;

const NextBtnContainer = styled.div`
  height: 81px;
  width: 100%;
  flex: 0;
`;

export default connect(
  null,
  null
)(withTracker(Testimonials, 'Signup - Testimonials - Page View'));
