import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import useHealthPlanPageUILogic from '../../hooks/useHealthPlanPageUILogic';
import useCommonPartnersUILogic from '../../hooks/useCommonPartnersUILogic';
import useGenericSignUpFunctions from '../../hooks/useGenericSignUpFunctions';
import withTracker from '../../wrappers/withTracker';
import AnimatedRoute from '../../components/AnimatedRoute';
import OshiRadioGroup from '../../components/OshiRadioGroup';
import { ROUTES } from '../../constants/routes';
import { setIsFetching } from '../../actions';
import { palette } from '../../theme/palette';
import { CURRENT_PARTNERS } from '../../constants/subscriptionStatus';
import {
  AetnaLogo,
  UHCLogo,
  FireflyLogo,
  BlueCrossLogo,
  UHCOxfordLogo,
  SurestLogo,
  ReferralWaiverLogo,
  CignaLogo,
  AllinaLogo,
} from '../../assets/svgs';
import Typography from '../../atoms/Typography';
import PageFooter from '../../components/PageFooter';
import useWindowSize from '../../hooks/useWindowSize'
import useOnboardingLogic from '../../hooks/useOnboardingLogic';

/**
 * @function HealthPlan2
 * @description Custom page that shows Health Plan page for the new generic sign up flow.
 * This page was based on the original HealthPlan.js page.
 * It fetches partner list to parse and display them as custom UI radio button group for the user
 * to select as new health plans. If user finds their enrolled Health plan then app process with sign up flow.
 * If user isn't enrolled to displayed health plans, the page offers a third option to select other health plans
 * that might have user's expected health plan and just in case a last option to record their Health Plan not found
 * at current Oshi's Health plans partnership.
 * Additionally handles custom UI events like navigating to New Partner page in case the user doesn't
 * find their Health plan within Oshi's current partners.
 * @param {{setIsFetching: (state: boolean) => void}} param - Map of features to be required by this hook. `setIsFetching` field is a
 * Redux store action-based function to show/hide loading mask screen
 * @returns {React.ReactElement}
 */
const HealthPlan2 = ({ setIsFetching: setFetching }) => {

  useOnboardingLogic();

  const {
    isFirstHealthPlanPartnerById,
    updatePatientLead,
    findSelectedPartnerByList,
  } = useGenericSignUpFunctions();
  const { parseList } = useHealthPlanPageUILogic({
    isFirstHealthPlanPartnerById,
  });
  const {
    partners,
    selectedPartner: partnerChoosen,
    animationDirection,
    setAnimationDirection,
    handleSelectPartnerClick,
    handleNewPartnerBtnClick,
    handleContinueBtnClick,
  } = useCommonPartnersUILogic({
    setIsFetching: setFetching,
    parseList,
    updatePatientLead,
    isFirstHealthPlanPartnerById,
    findSelectedPartnerByList,
  });

  const { screenSize } = useWindowSize();
  const renderCustomRadioLabel = React.useCallback(({ value }) => {
    switch (value) {
      case CURRENT_PARTNERS.AETNA:
        return <AetnaLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.UHC:
        return <UHCLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.UHC_OXFORD:
        return <UHCOxfordLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.BLUE_CROSS:
        return <BlueCrossLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.FIREFLY:
        return <FireflyLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.SUREST:
        return <SurestLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.CIGNA:
        return <CignaLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.ALLINA: //although allina is not included in the social flow we add it here for page consistency.
        return <AllinaLogo />;
      case CURRENT_PARTNERS.WAIVER:
        return <ReferralWaiverLogo fill={palette.coolGray500} />;
      case CURRENT_PARTNERS.OTHER:
        return (
          <Typography
            type='paragraph'
            styles={{
              fontWeight: '400',
              color: palette.navy500,
              textDecorationLine: 'underline',
            }}
          >
            Oshi Health is covered by my employer.
          </Typography>
        );
      default:
        return null;
    }
  }, []);

  const title = `Your first step toward lasting digestive relief.`;
  const subHeader = `We're in-network with the following major health plans. Select yours to get started.`;

  const getSubHeaderComponent = (subHeader) => {
    return <SubHeader>{subHeader}</SubHeader>;
  };

  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const SCROLL_PADDING = 20;

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight + SCROLL_PADDING);
    }
    // eslint-disable-next-line
  }, [footerRef.current?.offsetHeight]);

  return (
    <AnimatedRoute
      nextRoute={ROUTES.FEDERAL_PLAN}
      title={title}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      showProgressBar={false}
      animatedContentStyles={{ paddingTop: 0 }}
    >
      <Container style={{ paddingBottom: screenSize.isMobile ? footerHeight : 0 }}>
        <TopContent>
          {getSubHeaderComponent(subHeader)}
          <OshiRadioGroup
            data={partners}
            renderLabel={renderCustomRadioLabel}
            selectedOption={partnerChoosen?.selectedPartner}
            onSelectOption={handleSelectPartnerClick}
            containerStyles={{
              backgroundColor: palette.turquoise50,
              boxSizing: 'border-box',
              display: 'flex',
              flexGrow: '1',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '12px',
              border: '1px solid',
              borderRadius: '8px',
              cursor: 'pointer',
              margin: '0',
              width: screenSize.isMobile ? '100%' : '48%',
              height: screenSize.isMobile ? 'auto' : '62px',
            }}
            labelContainerStyles={{
              alignItems: 'center',
            }}
            styles={{
              width: '100%',
              maxHeight: screenSize.isMobile ? '100%' : '300px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '12px',
              flexDirection: 'column',
              height: 'auto',
            }}
            animatedBorderStyles={{
              checked: palette.turquoise500,
              unchecked: palette.transparent,
            }}
          />
        </TopContent>
      </Container>
      <PageFooter
        containerRef={footerRef}
        primaryText='Continue'
        secondaryText={`My health plan isn't listed. `}
        onClickContinue={handleContinueBtnClick}
        onClickSkip={handleNewPartnerBtnClick}
        isContinueDisabled={!partnerChoosen}
        buttonGroupStyles={{
          flexDirection: 'column-reverse',
          gap: '8px',
          margin: screenSize.isMobile ? '12px 0px' : '12px 16px',
          maxWidth: '100%',
        }}
        contentStyles={{ width: '100%' }}
        styles={{ maxWidth: '100%' }}
        primaryButtonStyles={{
          maxWidth: '100%',
          fontWeight: '400',
          color: palette.navy500,
          margin: '0',
          minHeight: 'auto',
          display: 'flex',
          justifyContent: 'center',
          minWidth: '0',
          height: 'auto',
        }}
        secondaryButtonStyles={{
          margin: '0',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          width: '100%',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          textAlign: 'center',
          padding: '12px',
          height: 'auto',
          lineHeight: '1.4',
          minWidth: '0',
        }}
      />
    </AnimatedRoute>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const SubHeader = styled.p`
  font-family: Commissioner;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  word-wrap: break-word;
  color: ${palette.coolGray};
  padding: 0;
  padding-bottom: 48px;
  @media only screen and (max-width: 768px) {
    padding: 24px 0;
  }
`;

const TopContent = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export default connect(null, { setIsFetching })(
  withTracker(HealthPlan2, 'Signup - Health Plan Selection - Page View')
);
