import React from 'react';
import styled from 'styled-components';
import OshiLink from '../components/OshiLink';
import { palette } from '../theme/palette';

export function SignInLink({ onSignInClick, wrapperStyles = '' }) {
  const handleClick = () => {
    if (onSignInClick) {
      onSignInClick();
    }
  };

  return (
    <SignInWrapper style={{...wrapperStyles}}>
      <SignInContainer>
        <AccountText>Already have an account? </AccountText>
        <OshiLink
          containerStyles ={{flex: 'unset'}}
          styles={{ minWidth: 0, color: palette.navy500, textAlign: 'center' }}
          buttonTitle='Sign in.'
          onClick={handleClick}
        />
      </SignInContainer>
    </SignInWrapper>
  );
}

const SignInWrapper = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 24px;
`;

const SignInContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  flex-wrap: wrap;
  font-family: 'Usual', serif;
  color: ${palette.coolGray500};
  @media only screen and (min-width: 429px) {
    align-items: flex-end;
  }
`;

const AccountText = styled.div`
  align-items: center;
  display: flex;
  white-space: break-spaces;
  text-align: center;
  height: 41px;
`;
