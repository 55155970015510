import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ROUTES } from '../constants/routes';
import regex from '../utils/regex';
import { useSelector } from 'react-redux';
import { localStorageSave } from '../utils/localStorageHelper';

/**
 * @function useWaitlistPageUILogic
 * @description Custom hook that handles Waitlist UI logic functionalities.
 * It handles the creation of new-partner formik form data and its submission that leads to
 * the off-boarding page.
 * @param {(state: any): void}
 * @param {(name: string): void} pageViewEvent - Analytics segment action function to trigger when UI page is loaded.
 * @returns {{
 *   animationDirection: 'mount' | 'unmount',
 *   setAnimationDirection: (state: string): void,
 *   formik: FormikConfig,
 *   handleJoinWaitlistClick: (): void,
 * }}
 */
const useWaitlistPageUILogic = ({ setIsFetching, pageViewEvent }) => {
  const [animationDirection, setAnimationDirection] = useState('mount');
  const history = useHistory();
  const patientAddress = history?.location?.state?.address;
  const { partner = null } = history?.location?.state ?? {};

  const [showErrors, setShowErrors] = useState(false);
  const emailValidationSchema = Yup.object({
    email: Yup.string().required('Please enter a valid email.'),
    firstName: Yup.string().required('Enter a first name'),
    lastName: Yup.string().required('Enter a last name'),
  });
  const [isMedicaidMedicare, setIsMedicaidMedicare] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      healthPlan: partner?.name || '',
      company: '',
    },
    validateOnChange: true,
    validate,
    validationSchema: emailValidationSchema,
    onSubmit: () => handleJoinWaitlistClick(),
  });

  function validate() {
    const errors = {};
    if (!regex.email.test(formik.values.email)) {
      errors.email = 'Please enter a valid email';
    }

    return errors;
  }

  const handleJoinWaitlistClick = () => {
    const errorsArray = Object.keys(formik.errors);
    if (errorsArray.length > 0) {
      return setShowErrors(true);
    }
    /* Explanation for isMedicaidMedicare here: https://oshihealth.atlassian.net/browse/PEA-606*/
    history.push({
      pathname: ROUTES.OFF_BOARDING,
      state: {
        address: patientAddress,
        partner: isMedicaidMedicare ? partner?.id : null,
        primary_insurance: !isMedicaidMedicare
          ? formik.values.healthPlan
          : null,
        company: formik.values.employer,
        email: formik.values.email,
      },
    });
    localStorageSave('email', formik.values.email);
    localStorageSave('name', {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
    });
    setAnimationDirection('unmount');
  };

  const { key: onboardingFlowKey } = useSelector(
    (store) => store.uiReducer?.onboardingFlow
  );
  
  useEffect(() => {
    setIsMedicaidMedicare(partner?.id ? true : false);

    if (!patientAddress && onboardingFlowKey !== 'onboarding-3') {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientAddress]);

  return {
    animationDirection,
    setAnimationDirection,
    formik,
    handleJoinWaitlistClick,
    showErrors,
    isMedicaidMedicare,
  };
};

export default useWaitlistPageUILogic;
