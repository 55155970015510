import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import { SignInLink } from '../atoms/SignInLink';
import { NamePageSigninBtnPressed } from '../actions/segment';
import { useHistory } from 'react-router-dom';

import useWindowSize from '../hooks/useWindowSize';
import { palette } from '../theme/palette';

const PageFooter = React.forwardRef(
  (
    {
      onClickContinue = () => {},
      onClickSkip = () => {},
      isContinueDisabled,
      primaryText,
      secondaryText,
      styles = {},
      contentStyles = {},
      buttonGroupStyles = {},
      primaryButtonStyles = {},
      secondaryButtonStyles = {},
      isLoading,
      containerRef={}
    },
    ref
  ) => {
    const { screenSize } = useWindowSize();
    const history = useHistory();

    return (
      <FooterContainer
        ref={containerRef}
        style={{
          position: screenSize.isMobile ? 'fixed' : 'relative',
          boxSizing: 'border-box',
          bottom: 0,
          left: 0,
          flex: 0,
          boxShadow: screenSize.isMobile
            ? '0px -4px 10px 0px #0000001a'
            : 'none',
          padding: screenSize.isMobile ? '0 24px' : '0',
          width: '100%',
          backgroundColor: palette.white,
          ...styles,
        }}
      >
        <FooterContent style={{ ...contentStyles }}>
          <ButtonGroup style={{ ...buttonGroupStyles }}>
            {secondaryText && (
              <SkipButton
                onClick={onClickSkip}
                styles={{
                  margin: screenSize.isMobile ? '12px 0' : '16px 0 0',
                  alignItems: 'center',
                  color: palette.turquoise800,
                  backgroundColor: palette.white,
                  ...secondaryButtonStyles,
                }}
              >
                {secondaryText}
              </SkipButton>
            )}
            <NextButton
              disabled={isContinueDisabled}
              isLoading={isLoading}
              onClick={onClickContinue}
              styles={{
                margin: screenSize.isMobile ? '12px 0' : '16px 0 0',
                alignItems: 'center',
                ...primaryButtonStyles,
              }}
              ref={ref}
            >
              {primaryText}
            </NextButton>
          </ButtonGroup>
          <SignInLink
            wrapperStyles={{padding :'0 0 16px'}}
            onSignInClick={() => {
              NamePageSigninBtnPressed();
              history.push('/signin');
            }}
          />
        </FooterContent>
      </FooterContainer>
    );
  }
);

const FooterContainer = styled.div`
  z-index: 10;
  display: flex;
  align-items: flex-end;
  width: 100%;
  flex: 1;
  background-color: ${palette.white};


  @media only screen and (min-width: 769px) {
    align-items: flex-start;
    margin-top: 24px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  
  @media only screen and (min-width: 769px) {
    max-width: 100%;
  }
`;

const FooterContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding:0;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    justify-content: left;
  }
`;

const NextButton = styled(Button)`
  width: 100%;
  min-width: 166px;

  span {
    padding: 12px;
  }

  & + * {
    margin-top: 12px;
  }
  @media only screen and (min-width: 769px) {
    & + * {
      margin-top: 24px;
    }
  }
`;

const SkipButton = styled(Button)`
  min-width: 166px;
  width: 100%;
  box-shadow: inset 0 0 0 2px ${palette.turquoise200};
  & + * {
    margin-top: 12px;
  }
  @media only screen and (min-width: 769px) {
    & + * {
      margin-top: 24px;
    }
  }
`;

export default PageFooter;
