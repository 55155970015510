/* eslint-disable import/prefer-default-export */
import { localStorageGet } from './localStorageHelper';

export const getBirthdate = () => {
  try {
    const dob = localStorageGet('dob');
    if (!dob) return null;
    return new Date(
      dob.birthday.split(' / ').join('/')
    ).getTime();
  } catch (error) {
    console.log('getBirthdate error', error);
    return null;
  }
};
