import React from 'react';
import styled, { keyframes } from 'styled-components';
import Lottie from 'react-lottie';
import BackgroundAnimation from '../../assets/92-stat-background-animation.json';
import OshiNextButton from '../../components/OshiNextButton';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pageViewEvent, successRateSubmit } from '../../actions/segment';
import withTracker from '../../wrappers/withTracker';
import { palette } from '../../theme/palette';
import useWindowSize from '../../hooks/useWindowSize';
import underline from '../../img/successRateUnderline.svg';

function SuccessRate({ successRateSubmit }) {
  const backgroundLottieRef = React.useRef(null);
  const { screenSize } = useWindowSize();
  const history = useHistory();

  const getBackgroundLottie = () => (
    <Lottie
      options={{
        animationData: BackgroundAnimation,
        loop: false,
        autoplay: true,
      }}
      ref={backgroundLottieRef}
      ariaRole='none'
    />
  );

  const handleNext = () => {
    successRateSubmit();
    history.push('gi-conditions');
  };

  const renderPercentage = () => (
    <PercentageContainer>
      <PercentageText>92%</PercentageText>
      <PercentageUnderline src={underline} />
    </PercentageContainer>
  );
  return (
    <PageContainer>
      <BackgroundContainer>{getBackgroundLottie()}</BackgroundContainer>
      <MainAnimationContainer>
        <TextContent>Let's get you feeling better!</TextContent>

        <Headline>
          {renderPercentage()} of patients find relief in 10 weeks or less
        </Headline>

        <NextBtnContainer>
          <NextBtnWrapper>
            <OshiNextButton
              containerStyles={{
                boxSizing: 'border-box',
                bottom: 0,
                padding: screenSize.isMobile ? '0 24px' : '0',
                left: 0,
                flex: 0,
                backgroundColor: 'transparent',
                margin: 'auto',
                maxWidth: '648px',
              }}
              buttonContainerStyles={{ alignItems: 'center' }}
              styles={{ width: '100%' }}
              onClick={handleNext}
              buttonTitle={
                <span>
                  Continue <RightIconArrow>→</RightIconArrow>
                </span>
              }
            />
          </NextBtnWrapper>
        </NextBtnContainer>
      </MainAnimationContainer>
    </PageContainer>
  );
}

const slideIn = keyframes`
  from {
    transform: translateX(-100%); 
    opacity: 0; 
  }
  50% {
    transform: translateX(5%);
    opacity: 1;
  }
  to {
    transform: translateX(0); 
    opacity: 1;
  }
`;

const scaleIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0; 
  }
  50% {
    transform: scale(105%);
    opacity: 1;
  }
  100% {
    transform: scale(100%); 
    opacity: 1;
  }
`;

const slideInFromBottom = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0; 
  }
  50% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(0); 
    opacity: 1;
  }
`;

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
`;

const MainAnimationContainer = styled.div`
  width: 100%;
  height: min-content;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px;
  z-index: 1;
  overflow: hidden;
`;

const RightIconArrow = styled.span`
  font-size: 1em;
  padding-left: 10px;

  @media only screen and (min-width: 769px) {
    padding-left: 8px;
  }
`;

const BackgroundContainer = styled.div`
  position: fixed;
  top: -50%;
  left: -30%;
  width: 200%;
  overflow: hidden;
  height: 200%;
  background-color: ${palette.navy500};
`;
const PercentageText = styled.div`
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  margin: -5px 0;
`;
const PercentageUnderline = styled.img`
  animation: ${scaleIn} 1s ease-out forwards;
  animation-delay: 2.5s;
  width: 62px;
  opacity: 0;
  transform-origin: left center;
`;
const PercentageContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: fit-content;
`;

const Headline = styled.h1`
  color: ${palette.white};
  font-family: 'Source Serif Pro';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  flex: 0;
  opacity: 0;
  animation: ${slideInFromBottom} 1s ease-out forwards;
  animation-delay: 2s;
`;

const TextContent = styled.div`
  color: ${palette.white};
  font-family: 'Commissioner';
  font-size: 22px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  font-size: 18px;
  flex: 0;
  opacity: 0;
  animation: ${slideIn} 1s ease-out forwards;
  animation-delay: 1s;
  margin-bottom: 16px;
`;

const NextBtnWrapper = styled.div`
  min-height: 91px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  animation: ${slideInFromBottom} 0.5s ease-out forwards;
  animation-delay: 2.2s;

  @media only screen and (min-width: 769px) {
    position: unset;
    background-color: transparent;
  }

  @media only screen and (max-height: 430px) {
    min-height: 61px;
  }
`;

const NextBtnContainer = styled.div`
  height: 81px;
  width: 100%;
  flex: 0;
`;

export default connect(null, { pageViewEvent, successRateSubmit })(
  withTracker(SuccessRate, 'Signup - SuccessRate - Page View')
);
