import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import AnimatedRoute from '../../components/AnimatedRoute';
import { palette } from '../../theme/palette';
import { pageViewEvent, SSPSubmitted } from '../../actions/segment';
import {
  localStorageGet,
  localStorageSave,
} from '../../utils/localStorageHelper';
import OshiMultiCheckbox from '../../components/OshiMultiCheckbox';
import useWindowSize from '../../hooks/useWindowSize';
import withTracker from '../../wrappers/withTracker';
import { createTrackEvent } from '../../actions/segment';
import { SYMPTOM_OPTIONS } from '../../constants/formOptions';
import NewCustomTitle from '../../components/NewCustomTitle';
import PageFooter from '../../components/PageFooter';

function SymptomsSelection({ SSPSubmitted }) {
  const symptoms = localStorageGet('symptomsSelect') || SYMPTOM_OPTIONS;
  const [animationDirection, setAnimationDirection] = useState('mount');
  const [items, setItems] = useState(symptoms);
  const [disabled, setDisabled] = useState(true);
  const { firstName } = localStorageGet('name');
  const { screenSize } = useWindowSize();

  const getTitle = (title, subHeader) => {
    return (
      <NewCustomTitle
        title={title}
        subHeader={subHeader}
        style={{ padding: '0' }}
        headlineStyle={{ padding: screenSize.isMobile ? '24px 0' : '0' }}
      />
    );
  };

  useEffect(() => {
    const validateSelection = () => {
      const someSelected = symptoms.some((item) => item.isChecked);
      setDisabled(!someSelected);
    };
    validateSelection();
  }, [symptoms]);

  const handleOnSubmit = () => {
    SSPSubmitted({
      selectedSymptoms: symptoms
        .filter((item) => item.isChecked)
        .map((item) => item.title),
    });
    return setAnimationDirection('unmount');
  };

  const onClickItem = (key) => {
    const updatedItems = items.map((item) => {
      if (item.key === key) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });

    setItems(updatedItems);
    localStorageSave('symptomsSelect', updatedItems);
  };

  const title = firstName ?
    `Nice to meet you, ${firstName}. What brings you to Oshi today?`
    : `What brings you to Oshi today?`;
  const subHeader = ' Select all GI symptoms you currently struggle with:';

  return (
    <PageContainer>
      <AnimatedRoute
        nextRoute='/success-rate'
        title={getTitle(title, subHeader)}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
        showProgressBar={true}
        showProviderBanner={true}
      >
        <Container>
          <Content>
            <TwoColumnContainer>
              <OshiMultiCheckbox
                data={symptoms}
                onChange={onClickItem}
              ></OshiMultiCheckbox>
            </TwoColumnContainer>
          </Content>
          <PageFooter
            primaryText='Continue'
            onClickContinue={handleOnSubmit}
            isContinueDisabled={disabled}
          />
        </Container>
      </AnimatedRoute>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TwoColumnContainer = styled.div`
  column-count: 1;
  column-gap: 8px;

  @media only screen and (min-width: 769px) {
    column-count: 2;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  flex: 1;
  padding-bottom: 168px;
  @media only screen and (min-width: 769px) {
    padding-bottom: 0px;
  }
`;

const Content = styled.div`
  font-family: 'Commissioner', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${palette.darkPrimaryText};
  flex: 1;
  font-size: 16px;
  padding: 0;

  @media only screen and (max-height: 300px) {
    padding: 0px 0px 120px 0;
  }

  @media only screen and (min-width: 1136px) {
    font-size: 24px;
  }
`;

export default connect(null, {
  createTrackEvent,
  pageViewEvent,
  SSPSubmitted,
})(withTracker(SymptomsSelection, 'Signup - Select Symptoms - Page View'));
