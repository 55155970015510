/* eslint-disable import/prefer-default-export */
export const segmentTypes = {
  PAGE_VIEW_EVENT: 'PAGE_VIEW_EVENT',
  PRIVACY_CONTAINER_EXPANDED: 'PRIVACY_CONTAINER_EXPANDED',
  PRIVACY_CONTAINER_SEEN: 'PRIVACY_CONTAINER_SEEN',
  PRIVACY_CONTAINER_ACCEPTED: 'PRIVACY_CONTAINER_ACCEPTED',
  PRIVACY_PAGE_EXIT: 'PRIVACY_PAGE_EXIT',
  VALUE_PROP_EVENT: 'VALUE_PROP_EVENT',
  LANDING_GET_STARTED: 'LANDING_GET_STARTED',
  LANDING_LOGIN: 'LANDING_LOGIN',
  INPUT_LEAVE_EVENT: 'INPUT_LEAVE_EVENT',
  ERROR: 'ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  INTRO_GET_STARTED: 'INTRO_GET_STARTED',
  USER_SELECT_SYMPTOMS: 'USER_SELECT_SYMPTOMS',
  USER_GI_CONDITIONS: 'USER_GI_CONDITIONS',
  USER_SUCCESS_RATE: 'USER_SUCCESS_RATE',
  USER_WE_CAN_HELP: 'USER_WE_CAN_HELP',
  USER_FULLNAME_SUBMIT: 'USER_FULLNAME_SUBMIT',
  USER_EMAIL_SUBMIT: 'USER_EMAIL_SUBMIT',
  USER_GENDER_SUBMIT: 'USER_GENDER_SUBMIT',
  USER_BIRTHDATE_SUBMIT: 'USER_BIRTHDATE_SUBMIT',
  USER_ADDRESS_SUBMIT: 'USER_ADDRESS_SUBMIT',
  USER_CONFIRM_ADDRESS_SUBMIT: 'USER_CONFIRM_ADDRESS_SUBMIT',
  USER_PASSWORD_SUBMIT: 'USER_PASSWORD_SUBMIT',
  USER_PRIVACY_SUBMIT: 'USER_PRIVACY_SUBMIT',
  USER_CALENDAR_SUBMIT: 'USER_CALENDAR_SUBMIT',
  USER_REVIEW_APPT_DETAILS_SUBMIT: 'USER_REVIEW_APPT_DETAILS_SUBMIT',
  USER_APPT_BOOKED_SUBMIT: 'USER_APPT_BOOKED_SUBMIT',
  USER_OFFBOARDING_SUBMIT: 'USER_OFFBOARDING_SUBMIT',
  EMAIL_CHECK_LIMIT_EXCEEDED_EXCEPTION: 'EMAIL_CHECK_LIMIT_EXCEEDED_EXCEPTION',
  EMAIL_ALREADY_IN_USE: 'EMAIL_ALREADY_IN_USE',
  SYMPTOMS_ENTERED: 'SYMPTOMS_ENTERED',
  USER_NAME_BACK_BUTTON: 'USER_NAME_BACK_BUTTON',
  USER_NAME_STEP_BACK_BUTTON: 'USER_NAME_BACK_BUTTON',
  USER_CONTACT_INFORMATION_BACK_BUTTON: 'USER_CONTACT_INFORMATION_BACK_BUTTON',
  USER_SEX_BACK_BUTTON: 'USER_SEX_BACK_BUTTON',
  USER_PIMID_BACK_BUTTON: 'USER_PIMID_BACK_BUTTON',
  USER_DOB_BACK_BUTTON: 'USER_DOB_BACK_BUTTON',
  USER_ADDRESS_BACK_BUTTON: 'USER_ADDRESS_BACK_BUTTON',
  USER_CONFIRM_ADDRESS_BACK_BUTTON: 'USER_CONFIRM_ADDRESS_BACK_BUTTON',
  USER_HEALTH_PLAN_BACK_BUTTON: 'USER_HEALTH_PLAN_BACK_BUTTON',
  USER_EMPLOYER_PAGE_BACK_BUTTON: 'USER_EMPLOYER_PAGE_BACK_BUTTON',
  USER_EMPLOYER_NOT_LISTED_BACK_BUTTON: 'USER_EMPLOYER_NOT_LISTED_BACK_BUTTON',
  USER_MEDICARE_MEDICAID_MASSHEALTH_BACK_BUTTON:
    'USER_MEDICARE_MEDICAID_MASSHEALTH_BACK_BUTTON',
  USER_PASSWORD_BACK_BUTTON: 'USER_PASSWORD_BACK_BUTTON',
  USER_PRIVACY_BACK_BUTTON: 'USER_PRIVACY_BACK_BUTTON',
  USER_CALENDAR_BACK_BUTTON: 'USER_CALENDAR_BACK_BUTTON',
  USER_REVIEW_APPT_DETAILS_BACK_BUTTON: 'USER_REVIEW_APPT_DETAILS_BACK_BUTTON',
  USER_OFFBOARDING_BACK_BUTTON: 'USER_OFFBOARDING_BACK_BUTTON',
  USER_GET_SYMPTOM_RELIEF_BACK_BUTTON: 'USER_GET_SYMPTOM_RELIEF_BACK_BUTTON',
  USER_ACCOUNT_CREATION_ERROR_BACK_BUTTON:
    'USER_ACCOUNT_CREATION_ERROR_BACK_BUTTON',
  USER_INSURANCE_INFORMATION_BACK_BUTTON:
    'USER_INSURANCE_INFORMATION_BACK_BUTTON',
  USER_INSURANCE_CARD_TAKE_PICTURE_BACK_BUTTON:
    'USER_INSURANCE_CARD_TAKE_PICTURE_BACK_BUTTON',
  USER_INSURANCE_CARD_IMAGE_UPLOAD_BACK_BUTTON:
    'USER_INSURANCE_CARD_IMAGE_UPLOAD_BACK_BUTTON',
  USER_POLICY_HOLDER_BACK_BUTTON: 'USER_POLICY_HOLDER_BACK_BUTTON',
  USER_POLICY_HOLDER_RELATIONSHIP_BACK_BUTTON:
    'USER_POLICY_HOLDER_RELATIONSHIP_BACK_BUTTON',
  USER_NETWORK_ERROR_BACK_BUTTON: 'USER_NETWORK_ERROR_BACK_BUTTON',
  USER_INTRO_TAB_ACTIVE_EVENT: 'USER_INTRO_TAB_ACTIVE_EVENT',
  USER_NAME_TAB_ACTIVE_EVENT: 'USER_NAME_TAB_ACTIVE_EVENT',
  USER_NAME_STEP_TAB_ACTIVE_EVENT: 'USER_NAME_STEP_TAB_ACTIVE_EVENT',
  USER_SELECT_SYMPTOMS_TAB_ACTIVE_EVENT: 'USER_SELECT_SYMPTOMS_TAB_ACTIVE_EVENT',
  USER_GI_CONDITIONS_TAB_ACTIVE_EVENT: 'USER_GI_CONDITIONS_TAB_ACTIVE_EVENT',
  USER_SUCCESS_RATE_TAB_ACTIVE_EVENT: 'USER_SUCCESS_RATE_TAB_ACTIVE_EVENT',
  USER_WE_CAN_HELP_TAB_ACTIVE_EVENT: 'USER_WE_CAN_HELP_TAB_ACTIVE_EVENT',
  USER_PIMID_TAB_ACTIVE_EVENT: 'USER_PIMID_TAB_ACTIVE_EVENT',
  USER_CONTACT_INFORMATION_TAB_ACTIVE_EVENT:
    'USER_CONTACT_INFORMATION_TAB_ACTIVE_EVENT',
  USER_SEX_TAB_ACTIVE_EVENT: 'USER_SEX_TAB_ACTIVE_EVENT',
  USER_PASSWORD_TAB_ACTIVE_EVENT: 'USER_PASSWORD_TAB_ACTIVE_EVENT',
  USER_PRIVACY_TAB_ACTIVE_EVENT: 'USER_PRIVACY_TAB_ACTIVE_EVENT',
  USER_ADDRESS_TAB_ACTIVE_EVENT: 'USER_ADDRESS_TAB_ACTIVE_EVENT',
  USER_HEALTH_PLAN_TAB_ACTIVE_EVENT: 'USER_HEALTH_PLAN_TAB_ACTIVE_EVENT',
  USER_HEALTH_PLAN_2_TAB_ACTIVE_EVENT: 'USER_HEALTH_PLAN_2_TAB_ACTIVE_EVENT',
  USER_EMPLOYER_PAGE_TAB_ACTIVE_EVENT: 'USER_EMPLOYER_PAGE_TAB_ACTIVE_EVENT',
  USER_MEDICARE_MEDICAID_MASSHEALTH_TAB_ACTIVE_EVENT:
    'USER_MEDICARE_MEDICAID_MASSHEALTH_TAB_ACTIVE_EVENT',
  USER_EMPLOYER_NOT_LISTED_TAB_ACTIVE_EVENT:
    'USER_EMPLOYER_NOT_LISTED_TAB_ACTIVE_EVENT',
  USER_CONFIRM_ADDRESS_TAB_ACTIVE_EVENT:
    'USER_CONFIRM_ADDRESS_TAB_ACTIVE_EVENT',
  USER_DOB_TAB_ACTIVE_EVENT: 'USER_DOB_TAB_ACTIVE_EVENT',
  USER_GET_SYMPTOM_RELIEF_TAB_ACTIVE_EVENT:
    'USER_GET_SYMPTOM_RELIEF_TAB_ACTIVE_EVENT',
  USER_CALENDAR_TAB_ACTIVE_EVENT: 'USER_CALENDAR_TAB_ACTIVE_EVENT',
  USER_REVIEW_APPT_DETAILS_TAB_ACTIVE_EVENT:
    'USER_REVIEW_APPT_DETAILS_TAB_ACTIVE_EVENT',
  USER_APPT_BOOKED_TAB_ACTIVE_EVENT: 'USER_APPT_BOOKED_TAB_ACTIVE_EVENT',
  USER_OFFBOARDING_TAB_ACTIVE_EVENT: 'USER_OFFBOARDING_TAB_ACTIVE_EVENT',
  USER_ACCOUNT_CREATION_ERROR_TAB_ACTIVE_EVENT:
    'USER_ACCOUNT_CREATION_ERROR_TAB_ACTIVE_EVENT',
  USER_SIGN_IN_TAB_ACTIVE_EVENT: 'USER_SIGN_IN_TAB_ACTIVE_EVENT',
  USER_RESET_PASS_EMAIL_TAB_ACTIVE_EVENT:
    'USER_RESET_PASS_EMAIL_TAB_ACTIVE_EVENT',
  USER_RESET_PASS_CODE_TAB_ACTIVE_EVENT:
    'USER_RESET_PASS_CODE_TAB_ACTIVE_EVENT',
  USER_RESET_PASS_TAB_ACTIVE_EVENT: 'USER_RESET_PASS_TAB_ACTIVE_EVENT',
  USER_INSURANCE_INFORMATION_TAB_ACTIVE_EVENT:
    'USER_INSURANCE_INFORMATION_TAB_ACTIVE_EVENT',
  USER_INSURANCE_CARD_TAKE_PICTURE_TAB_ACTIVE_EVENT:
    'USER_INSURANCE_CARD_TAKE_PICTURE_TAB_ACTIVE_EVENT',
  USER_INSURANCE_CARD_IMAGE_UPLOAD_TAB_ACTIVE_EVENT:
    'USER_INSURANCE_CARD_IMAGE_UPLOAD_TAB_ACTIVE_EVENT',
  USER_POLICY_HOLDER_TAB_ACTIVE_EVENT: 'USER_POLICY_HOLDER_TAB_ACTIVE_EVENT',
  USER_POLICY_HOLDER_RELATIONSHIP_TAB_ACTIVE_EVENT:
    'USER_POLICY_HOLDER_RELATIONSHIP_TAB_ACTIVE_EVENT',
  USER_NETWORK_ERROR_TAB_ACTIVE_EVENT: 'USER_NETWORK_ERROR_TAB_ACTIVE_EVENT',
  USER_WAITLIST_TAB_ACTIVE_EVENT: 'USER_WAITLIST_TAB_ACTIVE_EVENT',
  USER_WAITLIST_TAB_INACTIVE_EVENT: 'USER_WAITLIST_TAB_INACTIVE_EVENT',
  USER_NAME_TAB_INACTIVE_EVENT: 'USER_NAME_TAB_INACTIVE_EVENT',
  USER_NAME_STEP_TAB_INACTIVE_EVENT: 'USER_NAME_STEP_TAB_INACTIVE_EVENT',
  USER_INTRO_TAB_INACTIVE_EVENT: 'USER_INTRO_TAB_INACTIVE_EVENT',
  USER_SELECT_SYMPTOMS_TAB_INACTIVE_EVENT: 'USER_SELECT_SYMPTOMS_TAB_INACTIVE_EVENT',
  USER_GI_CONDITIONS_TAB_INACTIVE_EVENT: 'USER_GI_CONDITIONS_TAB_INACTIVE_EVENT',
  USER_WE_CAN_HELP_TAB_INACTIVE_EVENT: 'USER_WE_CAN_HELP_TAB_INACTIVE_EVENT',
  USER_PIMID_TAB_INACTIVE_EVENT: 'USER_PIMID_TAB_INACTIVE_EVENT',
  USER_CONTACT_INFORMATION_TAB_INACTIVE_EVENT:
    'USER_CONTACT_INFORMATION_TAB_INACTIVE_EVENT',
  USER_SEX_TAB_INACTIVE_EVENT: 'USER_SEX_TAB_INACTIVE_EVENT',
  USER_PASSWORD_TAB_INACTIVE_EVENT: 'USER_PASSWORD_TAB_INACTIVE_EVENT',
  USER_PRIVACY_TAB_INACTIVE_EVENT: 'USER_PRIVACY_TAB_INACTIVE_EVENT',
  USER_ADDRESS_TAB_INACTIVE_EVENT: 'USER_ADDRESS_TAB_INACTIVE_EVENT',
  USER_HEALTH_PLAN_TAB_INACTIVE_EVENT: 'USER_HEALTH_PLAN_TAB_INACTIVE_EVENT',
  USER_HEALTH_PLAN_2_TAB_INACTIVE_EVENT: 'USER_HEALTH_PLAN_2_TAB_INACTIVE_EVENT',
  USER_EMPLOYER_PAGE_TAB_INACTIVE_EVENT:
    'USER_EMPLOYER_PAGE_TAB_INACTIVE_EVENT',
  USER_EMPLOYER_NOT_LISTED_TAB_INACTIVE_EVENT:
    'USER_EMPLOYER_NOT_LISTED_TAB_INACTIVE_EVENT',
  USER_MEDICARE_MEDICAID_MASSHEALTH_TAB_INACTIVE_EVENT:
    'USER_MEDICARE_MEDICAID_MASSHEALTH_TAB_INACTIVE_EVENT',
  USER_CONFIRM_ADDRESS_TAB_INACTIVE_EVENT:
    'USER_CONFIRM_ADDRESS_TAB_INACTIVE_EVENT',
  USER_DOB_TAB_INACTIVE_EVENT: 'USER_DOB_TAB_INACTIVE_EVENT',
  USER_GET_SYMPTOM_RELIEF_TAB_INACTIVE_EVENT:
    'USER_GET_SYMPTOM_RELIEF_TAB_INACTIVE_EVENT',
  USER_CALENDAR_TAB_INACTIVE_EVENT: 'USER_CALENDAR_TAB_INACTIVE_EVENT',
  USER_REVIEW_APPT_DETAILS_TAB_INACTIVE_EVENT:
    'USER_REVIEW_APPT_DETAILS_TAB_INACTIVE_EVENT',
  USER_APPT_BOOKED_TAB_INACTIVE_EVENT: 'USER_APPT_BOOKED_TAB_INACTIVE_EVENT',
  USER_OFFBOARDING_TAB_INACTIVE_EVENT: 'USER_OFFBOARDING_TAB_INACTIVE_EVENT',
  USER_SIGN_IN_TAB_INACTIVE_EVENT: 'USER_SIGN_IN_TAB_INACTIVE_EVENT',
  USER_RESET_PASS_EMAIL_TAB_INACTIVE_EVENT:
    'USER_RESET_PASS_EMAIL_TAB_INACTIVE_EVENT',
  USER_RESET_PASS_CODE_TAB_INACTIVE_EVENT:
    'USER_RESET_PASS_CODE_TAB_INACTIVE_EVENT',
  USER_RESET_PASS_TAB_INACTIVE_EVENT: 'USER_RESET_PASS_TAB_INACTIVE_EVENT',
  USER_NETWORK_ERROR_TAB_INACTIVE_EVENT:
    'USER_NETWORK_ERROR_TAB_INACTIVE_EVENT',
  USER_INSURANCE_INFORMATION_TAB_INACTIVE_EVENT:
    'USER_INSURANCE_INFORMATION_TAB_INACTIVE_EVENT',
  USER_INSURANCE_CARD_TAKE_PICTURE_TAB_INACTIVE_EVENT:
    'USER_INSURANCE_CARD_TAKE_PICTURE_TAB_INACTIVE_EVENT',
  USER_INSURANCE_CARD_IMAGE_UPLOAD_TAB_INACTIVE_EVENT:
    'USER_INSURANCE_CARD_IMAGE_UPLOAD_TAB_INACTIVE_EVENT',
  USER_POLICY_HOLDER_TAB_INACTIVE_EVENT:
    'USER_POLICY_HOLDER_TAB_INACTIVE_EVENT',
  USER_POLICY_HOLDER_RELATIONSHIP_TAB_INACTIVE_EVENT:
    'USER_POLICY_HOLDER_RELATIONSHIP_TAB_INACTIVE_EVENT',
  BOOK_ANOTHER_APPT_SELECTED: 'BOOK_ANOTHER_APPT_SELECTED',
  BACK_APPTS_SELECTED: 'BACK_APPTS_SELECTED',
  USER_ACCOUNT_CREATION_ERROR_TAB_INACTIVE_EVENT:
    'USER_ACCOUNT_CREATION_ERROR_TAB_INACTIVE_EVENT',
  AGE_MUST_BE_AT_LEAST_18: 'AGE_MUST_BE_AT_LEAST_18',
  TRACK_TO_STRIPE_CHECKOUT_SESSION: 'TRACK_TO_STRIPE_CHECKOUT_SESSION',
  FEE_SCHEDULE_MODAL_VIEWED: 'FEE_SCHEDULE_MODAL_VIEWED',
  LEAVE_FEE_SCHEDULE_MODAL_VIEWED: 'LEAVE_FEE_SCHEDULE_MODAL_VIEWED',
  IDENTIFY_PATIENT: 'IDENTIFY_PATIENT',
  OFFBOARDING_BACK_BTN_CLICK: 'OFFBOARDING_BACK_BTN_CLICK',
  OFFBOARDING_CTA_CLICK: 'OFFBOARDING_CTA_CLICK',
  EMPLOYER_PAGE_SIGN_IN_BUTTON_PRESSED:
  'EMPLOYER_PAGE_SIGN_IN_BUTTON_PRESSED',
  FEDERALPLAN_PAGE_SIGN_IN_BUTTON_PRESSED:
  'FEDERALPLAN_PAGE_SIGN_IN_BUTTON_PRESSED',
  DESKTOPSIGNIN_INTRO_PAGE_SIGN_IN_BUTTON_PRESSED:
    'DESKTOPSIGNIN_INTRO_PAGE_SIGN_IN_BUTTON_PRESSED',
  DESKTOPSIGNIN_NAME_PAGE_SIGN_IN_BUTTON_PRESSED:
    'DESKTOPSIGNIN_NAME_PAGE_SIGN_IN_BUTTON_PRESSED',
  DESKTOPSIGNIN_CALENDAR_PAGE_SIGN_IN_BUTTON_PRESSED:
    'DESKTOPSIGNIN_CALENDAR_PAGE_SIGN_IN_BUTTON_PRESSED',
  DESKTOPSIGNIN_REVIEW_APPT_DETAILS_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_REVIEW_APPT_DETAILS_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_CALENDAR_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_CALENDAR_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_APPT_OVERVIEW_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_APPT_OVERVIEW_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_INSURANCE_INFORMATION_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_INSURANCE_INFORMATION_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_INSURANCE_CARD_IMAGE_UPLOAD_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_INSURANCE_CARD_IMAGE_UPLOAD_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_POLICY_HOLDER_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_POLICY_HOLDER_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_POLICY_HOLDER_RELATIONSHIP_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_POLICY_HOLDER_RELATIONSHIP_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_APPT_BOOKED_PAGE_SIGN_OUT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_APPT_BOOKED_PAGE_SIGN_OUT_BUTTON_PRESSED',
  DESKTOPSIGNIN_INVALID_EMAIL_OR_PASSWORD_ENTERED:
    'DESKTOPSIGNIN_INVALID_EMAIL_OR_PASSWORD_ENTERED',
  DESKTOPSIGNIN_FORGOT_PASSWORD_BUTTON_PRESSED:
    'DESKTOPSIGNIN_FORGOT_PASSWORD_BUTTON_PRESSED',
  DESKTOPSIGNIN_SIGN_IN_CREATE_ACCOUNT_BUTTON_PRESSED:
    'DESKTOPSIGNIN_SIGN_IN_CREATE_ACCOUNT_BUTTON_PRESSED',
  DESKTOPSIGNIN_SIGN_IN_BUTTON_PRESSED: 'DESKTOPSIGNIN_SIGN_IN_BUTTON_PRESSED',
};

export const userTypes = {
  STORE_PATIENT_PROFILE: 'STORE_PATIENT_PROFILE',
};

export const uiTypes = {
  IS_FETCHING: 'IS_FETCHING',
  SHOW_BANNER: 'SHOW_BANNER',
  CHANGE_PROGRESS: 'CHANGE_PROGRESS',
  CHANGE_SIGN_UP_FLOW: 'CHANGE_SIGN_UP_FLOW',
  SETUP_ONBOARDING_FLOW: 'SETUP_ONBOARDING_FLOW',
  TOTAL_PROGRESS_STEPS: 'TOTAL_PROGRESS_STEPS',
  IS_NAME_ROUTE: 'IS_NAME_ROUTE',
  HIDE_RIGHT_SIDED_BANNER: 'HIDE_RIGHT_SIDED_BANNER',
};

export const appointmentTypes = {
  STORE_AVAILABLE_APPTS: 'STORE_AVAILABLE_APPTS',
  APPOINTMENT_ERROR: 'APPOINTMENT_ERROR',
};
