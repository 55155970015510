import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import OshiInput from '../../components/OshiInput';
import AnimatedRoute from '../../components/AnimatedRoute';
import OshiLink from '../../components/OshiLink';
import regex from '../../utils/regex';
import getRepeatItemArray from '../../utils/getRepeatItemArray';
import Typography from '../../atoms/Typography';
import { palette } from '../../theme/palette';
import { LIST_OF_CURRENT_PARTNERS } from '../../constants/subscriptionStatus';
import {
  pageViewEvent,
  userFullNameSubmitEvent,
  NamePageSigninBtnPressed,
} from '../../actions/segment';
import { setShowBanner, setIsNameRoute } from '../../actions';
import useNamePageUILogic from '../../hooks/useNamePageUILogic';
import OshiNextButton from '../../components/OshiNextButton';
import useWindowSize from '../../hooks/useWindowSize';
import useOnboardingLogic from '../../hooks/useOnboardingLogic';

const title = 'What’s your name?';

function NameStep({
  pageViewEvent: pageViewUIEvent,
  userFullNameSubmitEvent: userFullNameSubmit,
  setShowBanner,
  NamePageSigninBtnPressed,
  setIsNameRoute,
}) {
  const { nextRoute } = useOnboardingLogic();
  const { partnerName } = useParams();
  const history = useHistory();
  const { screenSize } = useWindowSize();

  const {
    formik,
    showErrors,
    animationDirection,
    setAnimationDirection,
    clearParentErrorOnFocus,
    handleOnKeyPress,
    handleOnSubmit,
  } = useNamePageUILogic(
    LIST_OF_CURRENT_PARTNERS.includes(partnerName) ? partnerName : null,
    pageViewUIEvent,
    userFullNameSubmit,
    setShowBanner
  );

  useEffect(() => {
    // setIsNameRoute(true);
    return () => {
      setShowBanner(false);
      setIsNameRoute(false);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer>
      <AnimatedRoute
        nextRoute={nextRoute || '/select-symptoms'}
        title={title}
        titleStyle={{ width: '100%' }}
        animationDirection={animationDirection}
        setAnimationDirection={setAnimationDirection}
        showProgressBar={true}
        showProviderBanner={true}
      >
        <Container>
          <Content>
            <Form onKeyDown={handleOnKeyPress}>
              <OshiInput
                id='firstName'
                autoFocus
                type='text'
                label='First Name'
                maskPlaceholder=''
                onChange={formik.handleChange}
                value={formik.values.firstName}
                clearParentErrorOnFocus={clearParentErrorOnFocus}
                segmentLabel='Sign Up - First Name'
                mask={getRepeatItemArray(regex.name, 50)}
                error={showErrors && formik.errors.firstName === showErrors}
              />

              <OshiInput
                id='lastName'
                type='text'
                label='Last Name'
                maskPlaceholder=''
                segmentLabel='Sign Up - Last Name'
                onChange={formik.handleChange}
                value={formik.values.lastName}
                clearParentErrorOnFocus={clearParentErrorOnFocus}
                mask={getRepeatItemArray(regex.name, 50)}
                error={showErrors && formik.errors.lastName === showErrors}
              />

              {showErrors && (
                <ErrorContainer>
                  <Typography styles={{ color: palette.error }}>
                    {showErrors}
                  </Typography>
                </ErrorContainer>
              )}
            </Form>
          </Content>
        </Container>
        <NextBtnContainer>
          <OshiNextButton
            containerStyles={{
              position: screenSize.isMobile ? 'fixed' : 'relative',
              boxSizing: 'border-box',
              bottom: 0,
              left: 0,
              flex: 0,
              boxShadow: screenSize.isMobile
                ? '0px -4px 10px 0px #0000001a'
                : 'none',
              padding: '24px',
              paddingBottom: '0',
              width: '100%',
              backgroundColor: palette.white,
            }}
            buttonContainerStyles={{
              margin: screenSize.isMobile ? '12px 0' : '16px 0 0',
              alignItems: 'center',
            }}
            styles={{ width: '100%' }}
            buttonTitle={'Continue'}
            disabled={!formik.dirty && !formik.initialStatus.existingValues}
            onClick={handleOnSubmit}
          >
            <SignInContainer>
              <AccountText>Already have an account? </AccountText>
              <OshiLink
                styles={{ minWidth: 0, color: palette.navy500 }}
                buttonTitle='Sign in.'
                onClick={() => {
                  NamePageSigninBtnPressed();
                  history.push('/signin');
                }}
              />
            </SignInContainer>
          </OshiNextButton>
        </NextBtnContainer>
      </AnimatedRoute>
    </PageContainer>
  );
}

const NextBtnContainer = styled.div`
  width: 100%;
`;

const PageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  flex: 1;
  padding-bottom: 168px;
  @media only screen and (min-width: 769px) {
    padding-bottom: 0px;
  }
`;

const Content = styled.div`
  font-family: 'Commissioner', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: ${palette.darkPrimaryText};
  padding: 0;
  flex: 1;

  @media only screen and (min-width: 769px) {
    flex: 0;
    padding: 0px;
  }

  @media only screen and (min-width: 1136px) {
    font-size: 24px;
  }
`;

const Form = styled.form`
  width: 100%;
  flex: 1;
  @media only screen and (min-width: 429px) {
    flex: 0;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

const SignInContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-family: 'Usual', serif;
  color: ${palette.coolGray500};
  flex: 0;
  @media only screen and (min-width: 429px) {
    flex: 1;
    align-items: flex-end;
  }
`;

const AccountText = styled.div`
  align-items: center;
  display: flex;
  height: 41px;
`;

export default connect(null, {
  pageViewEvent,
  userFullNameSubmitEvent,
  NamePageSigninBtnPressed,
  setShowBanner,
  setIsNameRoute,
})(NameStep);
