import React from 'react';
import { useHistory } from 'react-router-dom';

import useGenericSignUpFunctions from './useGenericSignUpFunctions';
import { localStorageGet } from '../utils/localStorageHelper';
import {
  CURRENT_PARTNERS,
  FEDERAL_PARTNERS,
} from '../constants/subscriptionStatus';
import { ROUTES } from '../constants/routes';
import { useSelector } from 'react-redux';

/**
 * @module useFederalHealthPlanUILogic /hooks/useFederalHealthPlanUILogic
 * @description Custom hook for Federal Health Plan UI logic page
 * It handles current animation flow data, current progress value sign, and UI events when user clicks on yes or no buttons.
 * @param {setIsFetching(state: any): void} setIsFetching - Redux action function to show/hide loading mask screen
 * @param {(name: string): void} pageViewEvent - Analytics segment action function to trigger when UI page is loaded.
 * @returns {{
 *   animationDirection: 'mount' | 'unmount',
 *   setAnimationDirection: (state: string): void,
 *   handleOffboardingBtnClick(): void,
 *   handleContinueBtnClick(): void,
 * }}
 */
const useFederalHealthPlanUILogic = ({
  setIsFetching,
  skipAddress,
}) => {
  const history = useHistory();
  const location = history.location;
  const { updatePatientLead, findSelectedPartnerByList } =
    useGenericSignUpFunctions();
  const [animationDirection, setAnimationDirection] = React.useState('mount');
  const state = localStorageGet('state');
  const { address } = localStorageGet('address');
  const { isReferringProvider } = localStorageGet('isReferringProvider');
  const { selectedPartner } = localStorageGet('selectedPartner');
  const { partner } = localStorageGet('partner');

  const handleContinueBtnClick = React.useCallback(async () => {
    setIsFetching(true);

    try {
      if (skipAddress) {
        return setAnimationDirection('unmount');
      }

      if (location?.state?.partnerList && selectedPartner && address && state) {
        const { partnerList } = location.state;
        let chosenPartner = selectedPartner;

        if (selectedPartner === CURRENT_PARTNERS.UHC_OXFORD) {
          chosenPartner = CURRENT_PARTNERS.UHC;
        }
        const foundPartner = findSelectedPartnerByList(
          partnerList,
          chosenPartner
        );

        const foundPartnerState =
          foundPartner?.operatingStates?.includes(state) || null;

        if (!foundPartnerState) {
          setIsFetching(false);
          history.push({
            pathname: ROUTES.OFF_BOARDING,
            state: {
              address: {
                ...address,
                stateName: state,
              },
              partner: {
                id: chosenPartner,
                name: foundPartner?.display_name || '',
                notFoundState: true,
              },
            },
          });
          return;
        }
        await updatePatientLead({
          partner: isReferringProvider ? partner : chosenPartner,
          address,
        });
        setAnimationDirection('unmount');
      }
    } catch (e) {
      console.log('error', e);
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, partner, address, state]);

  const { key: onboardingFlowKey } = useSelector(
    (store) => store.uiReducer?.onboardingFlow
  );

  const handleOffboardingBtnClick = React.useCallback(() => {
    const isExperiment3 = onboardingFlowKey === 'onboarding-3';

    history.push({
      pathname: isExperiment3 ? ROUTES.WAITLIST : ROUTES.OFF_BOARDING,
      state: {
        address: {
          ...address,
          stateName: state,
        },
        partner: {
          id: FEDERAL_PARTNERS.MEDICARE_MEDICAID_MASSHEALTH,
          name: isExperiment3
            ? 'Medicare or Medicaid'
            : 'Medicare or Medicaid or MassHealth',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, address, partner]);

  return {
    animationDirection,
    setAnimationDirection,
    handleContinueBtnClick,
    handleOffboardingBtnClick,
  };
};

export default useFederalHealthPlanUILogic;
