// eslint-disable-next-line import/prefer-default-export
export const palette = {
  background: '#FFF',
  primary: '#F38E8E',
  secondary: '#254C52',
  darkGrey: '#EBEEF2',
  lighGrey: '#F6F7F9',
  lightTransparent: '#fefefe',
  white: '#fff',
  mediumTurqoise: '#3DD6D0',
  error: '#CA3103',
  error50: '#FAEAE6',
  error200: '#EAAD9A',
  errorBackground: 'rgba(202, 49, 3, 0.03)',
  greyBorderColor: '#C3CED2',
  greyText: '#818D9B',
  darkPrimaryText: '#355074',
  darkSecondaryText: '#818D9B',
  navy: '#355074',
  navy800: '#182433',
  navy700: '#23374D',
  navy600: '#2F4660',
  navy500: '#355074',
  navy50: '#EBEFF2',
  coolGray: '#66727F',
  coolGray800: '#292E33',
  coolGray500: '#66727F',
  coolGray200: '#C2C7CC',
  coolGray50: '#F0F1F2',
  turquoise50: '#ECFBFA',
  turquoise200: '#B1EFEC',
  turquoise500: '#3DD6D0',
  turquoise800: '#185653',
  tan200: '#EDE5DF',
  melon: '#FFB59D',
  melon800: '#66483F',
  melon500: '#FFB59D',
  melon300: '#FFD3C4',
  melon50: '#FFF8F5',
  transitionScreen: '#E8E2E0',
  cadetBlue: '#3B5B80',
  primaryLightColor: '#F0F4FB',
  disabledField: '#AFB6BE',
  transparent: 'rgba(0,0,0,0)',
};
