import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import useCommonPartnersUILogic from '../hooks/useCommonPartnersUILogic';
import useCurrentPartnersPageUILogic from '../hooks/useCurrentPartnersPageUILogic';
import useGenericSignUpFunctions from '../hooks/useGenericSignUpFunctions';
import { setIsFetching } from '../actions';
import AnimatedRoute from '../components/AnimatedRoute';
import withTracker from '../wrappers/withTracker';
import OshiLink from '../components/OshiLink';
import OshiNextButton from '../components/OshiNextButton';
import Typography from '../atoms/Typography';
import { palette } from '../theme/palette';
import OshiSelect from '../components/OshiSelect';
import { EmployerPageSigninBtnPressed } from '../actions/segment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { SignInLink } from '../atoms/SignInLink';
import useWindowSize from '../hooks/useWindowSize';
import Button from '../components/Button';

/**
 * @function CurrentPartners
 * @description Custom page that shows current health plans page for the new generic sign up flow.
 * It fetches partner list to parse and display them as custom UI select-option component for the user
 * to select as new health plans. If user finds their enrolled Health plan then app process with sign up flow.
 * If user isn't enrolled to displayed health plans, the page offers to record user's current health
 * plan & employer in the New Partner page then the app proceeds with offboarding process.
 * Additionally handles custom UI events like navigating to New Partner page in case the user doesn't
 * find their Health plan within Oshi's current partners.
 * @param {(state: boolean): void} setIsFetching - Redux store action-based function to show/hide loading mask screen
 * @returns {React.ReactElement}
 */
const CurrentPartners = ({ setIsFetching }) => {
  const { parseList } = useCurrentPartnersPageUILogic();
  const {
    isFirstHealthPlanPartnerById,
    updatePatientLead,
    findSelectedPartnerByList,
  } = useGenericSignUpFunctions();

  const { currentPartnersConfig, isAddressLaterStep } = useSelector(
    (store) => store.uiReducer?.onboardingFlow
  );

  const {
    partners,
    selectedPartner,
    animationDirection,
    setAnimationDirection,
    handleSelectPartnerClick,
    handleNewPartnerBtnClick,
    handleContinueBtnClick,
  } = useCommonPartnersUILogic({
    setIsFetching,
    parseList,
    updatePatientLead,
    isFirstHealthPlanPartnerById,
    findSelectedPartnerByList,
    isAddressLaterStep
  });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsFetching(false), []);

  const onSignInClick = () => {
    EmployerPageSigninBtnPressed();
    history.push('/signin');
  };

  const history = useHistory();
  const { screenSize } = useWindowSize();

  return (
    <AnimatedRoute
      nextRoute={currentPartnersConfig?.nextRoute || '/password'}
      title={`Let's see if your employer covers Oshi Health.`}
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      showProgressBar={currentPartnersConfig?.showProgressBar}
    >
      <Container>
        <Typography
          type={'paragraph'}
          styles={{ fontWeight: '400', color: palette.coolGray500 }}
        >
          The following employers currently cover Oshi Health GI services. Use
          the dropdown below to select your employer or the employer through
          which you receive health benefits.
        </Typography>
      </Container>
      <OshiSelect
        id='partners'
        label='Employer'
        data={partners}
        storageKey={'selectedPartner'}
        onChange={handleSelectPartnerClick}
        style={{ marginTop: '24px' }}
      />
      <OshiNextButton
        onClick={handleContinueBtnClick}
        disabled={!selectedPartner}
        buttonTitle='Continue'
      >
        {currentPartnersConfig?.showSecondaryBtn ? (
          <SecondaryButton
            onClick={handleNewPartnerBtnClick}
            styles={{
              margin: screenSize.isMobile ? '12px 0' : '16px 0 0',
              alignItems: 'center',
              color: palette.turquoise800,
              backgroundColor: palette.white,
            }}
          >
            {"My employer isn't listed."}
          </SecondaryButton>
        ) : (
          <OshiLink
            onClick={handleNewPartnerBtnClick}
            containerStyles={{ justifyContent: 'center', marginTop: 10 }}
            styles={{
              textTransform: 'none',
              width: '100%',
              fontWeight: '400',
              color: palette.navy500,
            }}
            buttonTitle='My employer is not on this list.'
          ></OshiLink>
        )}
      </OshiNextButton>
      {currentPartnersConfig?.showSignin && <SignInLink onSignInClick={onSignInClick} />}
    </AnimatedRoute>
  );
};

const SecondaryButton = styled(Button)`
  min-width: 166px;
  width: 100%;
  max-width: 100%;
  text-transform: initial;
  box-shadow: inset 0 0 0 2px ${palette.turquoise200};
  white-space: normal;  
  word-wrap: break-word; 
  overflow-wrap: break-word;
  text-align: center; 
  padding: 12px;
  height: auto; 
  line-height: 1.4; 

  & + * {
    margin-top: 12px;
  }
  @media only screen and (min-width: 769px) {
    & + * {
      margin-top: 24px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export default connect(null, { setIsFetching })(
  withTracker(CurrentPartners, 'Signup - Employer Page - Page View')
);
