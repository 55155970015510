import { useMemo } from 'react';
import {
  CURRENT_PARTNERS,
  PARTNERS_TYPE,
} from '../constants/subscriptionStatus';
import { localStorageGet } from '../utils/localStorageHelper';

/**
 * @function useCurrentPartnersPageUILogic
 * @description Custom hook to handle customized UI logic operations for current partners UI page
 * These operations are contained in functions that, for instance, parses partner list data
 * to display in this UI page; another function memoizes partner list saved from LocalStorage.
 * @returns {{
 *   parseList: PartnerUI[],
 *   selectedPartner: (state: Partner): void,
 * }}
 */
const useCurrentPartnersPageUILogic = () => {
  const memoizedPartner = useMemo(
    () => localStorageGet('selectedPartner')?.selectedPartner || null,
    []
  );

  const parseList = (list) => {
    list.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    
    const validPartnerTypes = [
      PARTNERS_TYPE.PARTNER,
      PARTNERS_TYPE.HEALTH_PLAN,
      PARTNERS_TYPE.REFERRING_PROVIDER,
      PARTNERS_TYPE.EMPLOYER,
    ];

    const filteredList = list?.filter(
      (it) =>
        it.system_partner_id !== CURRENT_PARTNERS.AETNA &&
        it.system_partner_id !== CURRENT_PARTNERS.UHC &&
        it.system_partner_id !== CURRENT_PARTNERS.BLUE_CROSS &&
        it.system_partner_id !== CURRENT_PARTNERS.FIREFLY &&
        it.system_partner_id !== CURRENT_PARTNERS.SUREST &&
        it.system_partner_id !== CURRENT_PARTNERS.CIGNA &&
        it.system_partner_id !== CURRENT_PARTNERS.ALLINA &&
        validPartnerTypes.includes(it.type) && // Check if it.type is one of the valid types
        it.is_active
    );
    return filteredList.map((partner) => ({
      value: partner.system_partner_id,
      label: partner.display_name,
      item: partner,
    }));
  };

  return {
    parseList,
    selectedPartner: memoizedPartner || null,
  };
};

export default useCurrentPartnersPageUILogic;
