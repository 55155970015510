import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { setIsFetching } from '../actions';
import AnimatedRoute from '../components/AnimatedRoute';
import withTracker from '../wrappers/withTracker';
import OshiButton from '../components/Button';
import useFederalHealthPlanUILogic from '../hooks/useFederalHealthPlanUILogic';
import { palette } from '../theme/palette';
import { FederalPlanSigninBtnPressed } from '../actions/segment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { SignInLink } from '../atoms/SignInLink';
import useOnboardingLogic from '../hooks/useOnboardingLogic';

/**
 * @module FederalHealthPlan/React.Component
 * @file /src/containers/FederalHealthPlan.js
 * @description Custom page that asks user if user has Medicare or Medicaid federal health plans.
 * @param {setIsFetching(state: boolean): void} setIsFetching - Redux store action-based function to show/hide loading mask screen
 * @returns {React.ReactElement}
 */
const FederalHealthPlan = (props) => {
  const { setIsFetching: setFetching } = props;
  const history = useHistory();

  const { federalPlanConfig } = useSelector(
    (store) => store.uiReducer?.onboardingFlow
  );

  const { nextRoute } = useOnboardingLogic();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFetching(false), []);

  const {
    animationDirection,
    setAnimationDirection,
    handleContinueBtnClick,
    handleOffboardingBtnClick,
  } = useFederalHealthPlanUILogic({ setIsFetching: setFetching, skipAddress: federalPlanConfig?.skipAddress });

  const onSignInClick = () => {
    FederalPlanSigninBtnPressed();
    history.push('/signin');
  };

  return (
    <AnimatedRoute
      nextRoute={nextRoute || '/password'}
      title='Do you have Medicare, Medicaid, or MassHealth?'
      animationDirection={animationDirection}
      setAnimationDirection={setAnimationDirection}
      showProgressBar={federalPlanConfig?.showProgress}
    >
      <Container>
        <Content>
          <Title>
            We currently do not serve Medicare, Medicaid, or MassHealth members.
          </Title>
          <ButtonContainer>
            <Button onClick={handleOffboardingBtnClick}>Yes</Button>
            <Button onClick={handleContinueBtnClick}>No</Button>
          </ButtonContainer>
        </Content>
      </Container>
      {federalPlanConfig?.showSignIn && <SignInLink onSignInClick={onSignInClick} />}
    </AnimatedRoute>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.p`
font-family: 'Usual';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
color: ${palette.coolGray500};

@media only screen and (min-width: 769px) {
  margin-bottom: 42px;
`;

const Button = styled(OshiButton)`
  width: 100%;
  @media screen and (min-width: 769px) {
    width: 48%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  & > button:not(:last-child) {
    margin-bottom: 24px;
  }
  @media screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    & button {
      width: 48%;
    }
    & > button:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export default connect(null, { setIsFetching })(
  withTracker(FederalHealthPlan, 'Signup - Medicare/Medicaid - Page View')
);
