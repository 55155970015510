/*
 *
 * Definitions on the onboarding several flows
 **/

import { ROUTES } from './routes';

export const FLOW_TYPE = {
  SOCIAL: 'social',
  PAYER: 'payer',
};

export const OnboardingFlows = [
  {
    entryUrl: '/intro',
    isOnboardingFlow: true,
    key: 'onboarding-1',
    steps: [
      { step: 0, routes: [ROUTES.INTRO, ROUTES.NAME_STEP] },
      { step: 1, routes: [ROUTES.EMAIL] },
      { step: 2, routes: [ROUTES.GENDER] },
      { step: 3, routes: [ROUTES.BIRTHDATE] },
      { step: 4, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      {
        step: 5,
        routes: [
          ROUTES.HEALTH_PLAN,
          ROUTES.CURRENT_PARTNERS,
          ROUTES.NEW_PARTNER,
        ],
      },
      { step: 6, routes: [ROUTES.FEDERAL_PLAN] },
      { step: 7, routes: [ROUTES.PASSWORD] },
      { step: 8, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
  {
    entryUrl: '/intro-2',
    key: 'onboarding-2',
    isOnboardingFlow: true,
    steps: [
      { step: 0, routes: [ROUTES.INTRO_2, ROUTES.NAME_STEP] },
      { step: 1, routes: [ROUTES.SELECT_SYMPTOMS] },
      { step: 2, routes: [ROUTES.GI_CONDITIONS, ROUTES.SUCCESS_RATE] },
      { step: 3, routes: [ROUTES.HEALTH_GOALS, ROUTES.WE_CAN_HELP] },
      { step: 4, routes: [ROUTES.EMAIL] },
      { step: 5, routes: [ROUTES.GENDER] },
      { step: 6, routes: [ROUTES.BIRTHDATE] },
      { step: 7, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      {
        step: 8,
        routes: [
          ROUTES.HEALTH_PLAN,
          ROUTES.CURRENT_PARTNERS,
          ROUTES.NEW_PARTNER,
        ],
      },
      { step: 9, routes: [ROUTES.FEDERAL_PLAN] },
      { step: 10, routes: [ROUTES.PASSWORD] },
      { step: 11, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
  {
    entryUrl: '/intro-2/:partnerName',
    isOnboardingFlow: true,
    key: 'onboarding-2-partnerName',
    steps: [
      { step: 0, routes: [ROUTES.INTRO_2, ROUTES.NAME_STEP] },
      { step: 1, routes: [ROUTES.SELECT_SYMPTOMS] },
      { step: 2, routes: [ROUTES.GI_CONDITIONS, ROUTES.SUCCESS_RATE] },
      { step: 3, routes: [ROUTES.HEALTH_GOALS, ROUTES.WE_CAN_HELP] },
      { step: 4, routes: [ROUTES.EMAIL] },
      { step: 5, routes: [ROUTES.GENDER] },
      { step: 6, routes: [ROUTES.BIRTHDATE] },
      { step: 7, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      { step: 8, routes: [ROUTES.PASSWORD] },
      { step: 9, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
  {
    entryUrl: ROUTES.HEALTH_PLAN_2,
    isOnboardingFlow: true,
    isAddressLaterStep: true,
    currentPartnersConfig: {
      showProgressBar: false,
      showSignIn: true,
      showSecondaryBtn: true,
      nextRoute: ROUTES.NAME_STEP
    },
    federalPlanConfig: {
      showProgress: false,
      showSignIn: true,
      skipAddress: true
    },
    key: 'onboarding-3',
    steps: [
      { step: 0, routes: [ROUTES.HEALTH_PLAN_2, ROUTES.FEDERAL_PLAN, ROUTES.NAME_STEP] },
      { step: 1, routes: [ROUTES.EMAIL] },
      { step: 2, routes: [ROUTES.GENDER] },
      { step: 3, routes: [ROUTES.BIRTHDATE] },
      { step: 4, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      { step: 5, routes: [ROUTES.PASSWORD] },
      { step: 6, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
  {
    entryUrl: '/intro-2b',
    key: 'onboarding-2b',
    isOnboardingFlow: true,
    steps: [
      { step: 0, routes: [ROUTES.INTRO_2B] },
      { step: 1, routes: [ROUTES.SELECT_SYMPTOMS] },
      { step: 2, routes: [ROUTES.GI_CONDITIONS, ROUTES.SUCCESS_RATE] },
      { step: 3, routes: [ROUTES.HEALTH_GOALS, ROUTES.WE_CAN_HELP] },
      { step: 4, routes: [ROUTES.NAME_STEP] },
      { step: 5, routes: [ROUTES.EMAIL] },
      { step: 6, routes: [ROUTES.GENDER] },
      { step: 7, routes: [ROUTES.BIRTHDATE] },
      { step: 8, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      {
        step: 9,
        routes: [
          ROUTES.HEALTH_PLAN,
          ROUTES.CURRENT_PARTNERS,
          ROUTES.NEW_PARTNER,
        ],
      },
      { step: 10, routes: [ROUTES.FEDERAL_PLAN] },
      { step: 11, routes: [ROUTES.PASSWORD] },
      { step: 12, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
  {
    entryUrl: '/intro-2b/:partnerName',
    isOnboardingFlow: true,
    key: 'onboarding-2b-partnerName',
    steps: [
      { step: 0, routes: [ROUTES.INTRO_2B] },
      { step: 1, routes: [ROUTES.SELECT_SYMPTOMS] },
      { step: 2, routes: [ROUTES.GI_CONDITIONS, ROUTES.SUCCESS_RATE] },
      { step: 3, routes: [ROUTES.HEALTH_GOALS, ROUTES.WE_CAN_HELP] },
      { step: 4, routes: [ROUTES.NAME_STEP] },
      { step: 5, routes: [ROUTES.EMAIL] },
      { step: 6, routes: [ROUTES.GENDER] },
      { step: 7, routes: [ROUTES.BIRTHDATE] },
      { step: 8, routes: [ROUTES.STREET_ADDRESS, ROUTES.ADDRESS] },
      { step: 9, routes: [ROUTES.PASSWORD] },
      { step: 10, routes: [ROUTES.PRIVACY_POLICY] },
    ],
  },
];
